import resource from 'resource-axios';
import axios from '../../base-comm-console';

import { config } from '../../../config';

export const ConsoleCommodity = resource(config.admin_api_commodity_url, {
    // fetchManageAndAssignCompanyToDealer: (params) => {
    //     return axios.get(`${config.admin_api_url}/admin/manage-and-assign-company-to-dealers`, params)
    // },

    // saveAndManageAndAssignCompanyToDealers: (params) => {
    //     return axios.post(`${config.admin_api_url}/admin/save-and-manage-and-assign-company-to-dealers`, params)
    // },

    // getCompanies: (params) => {
    // let queryString = "";
    // if(typeof params == 'object')
    // {//
    //     queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    // }
    //     return axios.get(`${config.admin_api_url}/admin/companies?${queryString}`)
    // },

    // getUserNegativeList: (user_id) => {
    //     return axios.get(`${config.admin_api_url}/negative-list/user-negative-list/${user_id}`)
    // },

    // unblockNegativeList: (user_id, params) => {
    //     return axios.post(`${config.admin_api_url}/negative-list/unblock-user/${user_id}`, params)
    // },

    // userNegativeList: (user_id, params) => {
    // let queryString = "";
    // if(typeof params == 'object')
    // {//
    //     queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    // }
    //     return axios.get(`${config.admin_api_url}/negative-list/user-negative-list/${user_id}?${queryString}`)
    // },

    // userReferenceList: (user_id, params) => {
    // let queryString = "";
    // if(typeof params == 'object')
    // {//
    //     queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    // }
    //     return axios.get(`${config.admin_api_url}/users/${user_id}/reference-contacts?${queryString}`)
    // },

    // unblockNegativeWOAdCreditsList: (user_id, params) => {
    //     return axios.post(`${config.admin_api_url}/negative-list/unblock-user-without-adcredit/${user_id}`, params)
    // },

    // addToNegativeList: (user_id, params) => {
    //     return axios.post(`${config.admin_api_url}/negative-list/add-to-negative-list/${user_id}`, params)
    // },


    // fetchNegativeListReasons: (params) => {
    //     return axios.get(`${config.admin_api_url}/negative-list/reasons`, params)
    // },


    addUnits: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/units/create`, params)
    },
    addGroup: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/unit-groups/create`, params)
    },


    fetchCommGroup: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/unit-groups${queryString}`)
    },
    fetchCommTypeList: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/commodity-types?${queryString}`)
    },


    addCommFelids: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/commodities/create`, params)
    },
    updateCommFieldsinner: (params) => {
        return axios.patch(`${config.admin_api_commodity_url}/commodities/update`, params)
    },

    fetchUnits: (params)=>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/units?${queryString}`)
    },

    UpdateCommFeilds: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/commodities/update-fields`, params)
    },
    addNewField: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/commodity-fields/create`, params)
    },


    fetchCommodities: (params) => {
        // console.log('mydata',params)
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/commodities?${queryString}`)
    },

    fetchPublishedCommodities: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/commodities/published-commodities?${queryString}`);
    },
    fetchMakrtes: (params) => {

        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        // return axios.get(`${config.admin_api_commodity_url}/markets?${queryString}`)
        return axios.get(`${config.admin_api_commodity_url}/markets?${queryString}`)
    },
    createMarket: (params) => {

        return axios.post(`${config.admin_api_commodity_url}/markets/create`, params)
    },
    updateMarket:(params) => {
       
        return axios.post(`${config.admin_api_commodity_url}/markets/update`, params)
    },

    createSection: (params) => {

        return axios.post(`${config.admin_api_commodity_url}/commodity-sections/create`, params)
    },

    getSectionList: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/commodity-sections?${queryString}`)
    },
    createDevTerms:(params)=>{
        return axios.post(`${config.admin_api_commodity_url}/delivery-terms/create`, params)
    },
    createDevMode:(params)=>{
        console.log(params)
        return axios.post(`${config.admin_api_commodity_url}/delivery-modes/create`, params)
    },
    getdevterms: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/delivery-terms?${queryString}`)
    },
    getpaymentterms: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/payment-terms?${queryString}`)
    },


    createCluster: (params) => {

        return axios.post(`${config.admin_api_commodity_url}/contract-clusters/create`, params)
    },
    updateCluster:(params)=>{
        return axios.patch(`${config.admin_api_commodity_url}/contract-clusters/update`,params)
    },
    getCLusters: (params) => {

        return axios.post(`${config.admin_api_commodity_url}/contract-clusters`, params)
    },
    publishUnpublishClusters: (clusterId, publishedStatus) => {
        return axios.patch(`${config.admin_api_commodity_url}/contract-clusters/update-publish-status/${clusterId}/${publishedStatus}`);
    },
    getPublishedCluters:(params)=>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/contract-clusters?${queryString}`)
    },

    postPriceSectionData : (params) => {
        return axios.post(`${config.admin_api_commodity_url}/commodities/update-pricing-fields`,params)
    },

    createContract : (params) => {
        return axios.post(`${config.admin_api_commodity_url}/contracts/create`,params)
    },
    getContracts: (params) => {
        // console.log(params)
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/contracts?${queryString}`)
    },
    updateContract:(params) =>{
        return axios.post(`${config.admin_api_commodity_url}/contracts/update`,params)
    },
    Contracts: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }

        return axios.get(`${config.admin_api_commodity_url}/contracts/published-contracts?${queryString}`)


       },

       bestBuyers: (contractId, params) => {
        console.log('mydata',params, {contractId})
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/listings/contract/${contractId}/live-buyers?${queryString}`)
        // return axios.post(`${config.admin_api_commodity_url}64edeafee3cda1e6bb156d20`, params)
        // return axios.post(`${config.admin_api_commodity_url}}/listings/create`params)

    // return axios.post(`${config.admin_api_commodity_url}/markets`, params)
    },
    bestSeller: (contractId, params) => {
         console.log('bestSellerParams',params, {contractId})
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/listings/contract/${contractId}/live-sellers?${queryString}`)
        // return axios.post(`${config.admin_api_commodity_url}64edeafee3cda1e6bb156d20`, params)
        // return axios.post(`${config.admin_api_commodity_url}}/listings/create`params)

    // return axios.post(`${config.admin_api_commodity_url}/markets`, params)
    },
    createPaymentTerm: (params)=>{
        return axios.post(`${config.admin_api_commodity_url}/payment-terms/create`, params)
    },
    myListings: (params)=>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/listings?${queryString}`)
    },
    latestListings: (userId, params)=>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/listings/users/${userId}/contract-latest-listing?${queryString}`)
    },
    AddressAdd: (params) => {

        return axios.post(`${config.admin_api_commodity_url}/addresses/create`, params)
    },
    getAddress: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }

        return axios.get(`${config.admin_api_commodity_url}/addresses?${queryString}`)
    },

    getOpts: (params) =>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/users/otp-messages?${queryString}`)

    },

    expireListingAdmin:(params) =>{
        return axios.patch(`${config.admin_api_commodity_url}/listings/expire`,params)

    },

    expiryValues:(params)=>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/listings/expiry-values?${queryString}`)

    },
    fetchCommunication:(data, params) =>{
        let queryString = "";
        let paginateString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => `search[${key}]` + '=' + params[key]).join('&');
        }
        if(typeof data == 'object'){
            paginateString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        }
        console.log({queryString, paginateString})
        return axios.get(`${config.admin_api_commodity_url}/communications?${paginateString}&${queryString}`)
    },
    fetchCommunicationMessages:(commId,params) =>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/communications/${commId}/messages?${queryString}`)
    },
    paymentConfirmationApi:(commId) =>{
        
      
        return axios.post(`${config.admin_api_commodity_url}/communications/${commId}/buyer-payment-confirmed`)
    },
   
    fetchUsers: (paginate, params) => {
    // fetchUsers: (params) => {
        // console.log('pag',paginate)
        // console.log(paginate['page'], paginate.limit)
        let queryString = "";
        let paginateString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => `search[${key}]` + '=' + params[key]).join('&');
        }
        // if(typeof paginate == 'object')
        // {
        //     queryString = Object.keys(paginate).map(key => key + '=' + params[key]).join('&');
        // }
        // console.log({queryString})
        if(typeof paginate == 'object'){
            paginateString = Object.keys(paginate).map(key => key + '=' + paginate[key]).join('&');
        }
        // console.log(paginateString)
        return axios.get(`${config.admin_api_commodity_url}/users?${paginateString}&${queryString}`)
    },
    fetchUserIds: (params) => {
        let queryString;
        if(typeof params == 'object'){
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/users/get-user-ids?${queryString}`);
    },

    fetchPermissions: () => {

        return axios.get(`${config.admin_api_commodity_url}/permissions/grouped`)
    },

    createRole:(params)=>{
        return axios.post(`${config.admin_api_commodity_url}/roles/create`,params)
    },

    getRoles:()=>{
        return axios.get(`${config.admin_api_commodity_url}/roles`)
    },
    createCommodityType:(params)=> {
        return axios.post(`${config.admin_api_commodity_url}/commodity-types/create`,params)
    },
    updateCommodityType:(params)=>{
        return axios.post(`${config.admin_api_commodity_url}/commodity-types/update`,params)
    },
    adminDeals:(params) =>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/deals?${queryString}`)
    },
    createListing:(params,userId)=>{
        return axios.post(`${config.admin_api_commodity_url}/listings/users/${userId}/create`,params)
    },
    getAlladminUsers:(params)=>{
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/admin-users?${queryString}`)

    },
    dashboard: {
        dealsData:(params)=>{
            let queryString = "";
            if(typeof params == 'object')
            {
                queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            return axios.get(`${config.admin_api_commodity_url}/deals/dashboard-chart-data`);
        },
    },
    assignUsers :(params)=>{
        return axios.post(`${config.admin_api_commodity_url}/admin-users/assign`,params)

    },
    adminUserCreate : (params)=>{
        console.log({params})
        return axios.post(`${config.admin_api_commodity_url}/admin-users/create`,params)
    },
    adminUserupdate:(params)=>{
        
        return axios.patch(`${config.admin_api_commodity_url}/admin-users/update`,params)
    },
    publishUnpublish:(commodityid,status)=>{
        return axios.patch(`${config.admin_api_commodity_url}/commodities/update-publish-status/${commodityid}/${status}`)
    },
    getDeliveryModedata:()=>{
        return axios.get(`${config.admin_api_commodity_url}/delivery-modes`)
    },
    
    publishandunplush:(contractId,status)=>{
        return axios.patch(`${config.admin_api_commodity_url}/contracts/update-publish-status/${contractId}/${status}`)
    },
    sendMsg:(userId,params)=>{
        return axios.post(`${config.admin_api_commodity_url}/user/chats/${userId}/send-message`,params)
    },

    fetchMsgs:(userId,chatId,params)=>{
        console.log(userId,chatId,params)
        let queryString = "";
    if(typeof params == 'object')
    {
        queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
        return axios.get(`${config.admin_api_commodity_url}/user/chats/${userId}/${chatId}/fetch-messages?${queryString}`)
    },
    dealConsoleRejectReason:(params)=>{
        // console.log(params)
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.admin_api_commodity_url}/listings/reject-reasons?${queryString}`)
    
    },

    fetchUserCompanyAddresses:(userId, companyId, params) => {
        let queryString = "";
        if(typeof params === "object") {
            queryString = Object.keys(params).map(key => key + "=" + params[key].join('&'))
        }
        return axios.get(`${config.admin_api_commodity_url}/companies/${userId}/${companyId}/addresses?${queryString}`)
    },

    addUserCompanyAddress: (userId, params) => {
        return axios.post(`${config.admin_api_commodity_url}/companies/${userId}/add-address`, params);
    },

    updatePublishStatusOfContracts: (commodityId, status) => {
        console.log({commodityId, status})
        return axios.patch(`${config.admin_api_commodity_url}/contracts/update-publish-status-contracts/${commodityId}/${status}`)
    },

    getNumberOfContracts: (commodityId) => {
        // console.log({commodityId})
        return axios.get(`${config.admin_api_commodity_url}/contracts/count/${commodityId}`)
    },

    updateUser: (params, body) => {
        console.log({params, body})
        return axios.patch(`${config.admin_api_commodity_url}/users/update/user/${params}`, body)
    },

    fetchUserTimeline: (userId, params) => {
        let queryString = "";
        if(typeof params === "object") {
            queryString = Object.keys(params).map(key => key + "=" + params[key]).join('&')
        }
        return axios.get(`${config.admin_api_commodity_url}/timeline/${userId}?${queryString}`)
    },

    createIndustry: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/industries/create/`, params)
    },

    addUserPoc: (userId, params) => {
            // return axios.post(`${config.admin_api_commodity_url}/user-poc/${userId}/add`, params)
            return axios.post(`${config.admin_api_commodity_url}/user/poc/${userId}/create`, params)
    },

    fetchUserPocs: (userId, params) => {
        let queryString = "";
        if(typeof params === "object") {
            queryString = Object.keys(params).map(key => key + "=" + params[key]).join('&')
        }
        // return axios.get(`${config.admin_api_commodity_url}/user-poc/${userId}?${queryString}`)
        return axios.get(`${config.admin_api_commodity_url}/user/poc/${userId}?${queryString}`)
    },

    updateUserPoc: (pocId, params) => {
        return axios.patch(`${config.admin_api_commodity_url}/user/poc/update/${pocId}`, params)
    },

    deleteUserPoc: (pocId) => {
        return axios.patch(`${config.admin_api_commodity_url}/user/poc/delete/${pocId}`)
    },

    createDesignation: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/designations/create`, params)  
    },
    
    createEvent: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/events/create`, params)
    },

    createTransport: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/operations/transports/create`, params);
    },
    getTransports: (params) => {
        let queryString = "";
        if(typeof params === "object") {
            queryString = Object.keys(params).map(key => key + "=" + params[key]).join('&')
        }
        return axios.get(`${config.admin_api_commodity_url}/operations/transports?${queryString}`)
    },
    createNewTransport: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/operations/transports/create`, params);
    },
    updateTransportById: (transportId, params) => {
        return axios.patch(`${config.admin_api_commodity_url}/operations/transports/update/${transportId}`, params);
    },
    deleteTransportById: (id) => {
        return axios.delete(`${config.admin_api_commodity_url}/operations/transports/delete/${id}`)
    },
    getVehicleStatus: () => {
        return axios.get(`${config.admin_api_commodity_url}/operations/vehicle-status`);
    },
    getVehicles: (params) => {
        let queryString = "";
        if(typeof params === "object") {
            queryString = Object.keys(params).map(key => key + "=" + params[key]).join('&')
        }
        return axios.get(`${config.admin_api_commodity_url}/operations/vehicles?${queryString}`)
    },
    createVehicle: (params) => {
        return axios.post(`${config.admin_api_commodity_url}/operations/vehicles/add`, params)
    },
    updateVehicleById: (params) => {
        return axios.patch(`${config.admin_api_commodity_url}/operations/vehicles/update`, params)
    },
    deleteVehicleById: (id) => {
        return axios.delete(`${config.admin_api_commodity_url}/operations/vehicles/delete/${id}`)
    },



    // Search User by contacts
    // searchUserByContact: (params) => {
    // let queryString = "";
    // if(typeof params == 'object')
    // {//
    //     queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    // }
    //     return axios.get(`${config.admin_api_url}/negative-list/search-user-by-contact-number?${queryString}`, params)
    // },

}, axios)