import React, { useEffect } from 'react';
import { socket } from "../../socket";
import { Link, useSearchParams } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import product_icon from '../../images/product_icon.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
// import Emitter from "../../services/emitter";
// import StoreIcon from '@mui/icons-material/Store';
import Rating from '@mui/material/Rating';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import ReactGA from "react-ga";
import ReactGA from 'react-ga4';
import useMediaQuery from '@mui/material/useMediaQuery';
import { storeCTAClick } from '../../helpers';
import { User } from './../../api/resources/User';
// import Badges from './Badges';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  checkAndLoadUser,
  fetchUnreadNotificationCount,
  loginUser,
  logoutUser,
} from '../../redux/actions/userActions';
import {
  setLoginModal,
  setSignupModal,
} from '../../redux/actions/commonActions';
import { connect } from 'react-redux';
import { cEncrypt } from '../../helpers';

import { useSelector } from 'react-redux';
// import ErrorHandler from "./ErrorHandler";
// import ListItemButton from "@mui/material/ListItemButton";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import CancelIcon from '@mui/icons-material/Close';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AnimatedSuccess from '../partials/AnimatedSuccess';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
// import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
// import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
// import logo from "../../images/coalshastra-logo-white.png";
// import logoFav from "../../images/CSFav.png";
// import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
// import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import MoreIcon from "@mui/icons-material/MoreVert";
// import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import AddBoxIcon from '@mui/icons-material/AddBox';
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import CancelIcon from "@mui/icons-material/Cancel";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContentLoader from 'react-content-loader';
// import PaymentIcon from "@mui/icons-material/Payment";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import loadable from '@loadable/component';
// import { userCan } from "../../helpers";
// import LanguageTranslate from "../partials/LanguageTranslate";
// import asyncComponent from "./asyncComponent";
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditProfileDialog from '../profile/subusers/EditProfileDialog';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { style } from '@mui/system';
// import ProfileForm from "../profile/subusers/ProfileForm";
// import DehazeIcon from '@mui/icons-material/Dehaze';
import footerLogo from '../../../src/images/footerLogo1.png'
import companyLogo from '../../../src/images/footerLogo.gif'
import { Grid } from '@mui/material';
import trackingEvents from '../../eventsTracking';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDownloadMobile = loadable(() => {
  return import(
    /* webpackChunkName: "js/chunks/components-app-download-banners-AppDownloadMobile" */ '../app-download-banners/AppDownloadMobile'
  );
});
const AppDownloadWeb = loadable(() => {
  return import(
    /* webpackChunkName: "js/chunks/components-app-download-banners-AppDownloadWeb" */ '../app-download-banners/AppDownloadWeb'
  );
});

const LanguageTranslate = loadable(() =>
  import(
    /* webpackChunkName: "js/chunks/partials-LanguageTranslate" */ '../partials/UnderScoreLanguage'
  )
);
const LoginForm = loadable(
  () =>
    import(
      /* webpackChunkName: "js/chunks/partials-LoginForm" */ '../partials/LoginForm'
    ),
  {
    fallback: () => (
      <div className="text-center">
        <CircularProgress />
      </div>
    ),
  }
);
const ProfileCompanyInfo = loadable(
  () =>
    import(
      /* webpackChunkName: "js/chunks/profile-ProfileCompanyInfo" */ '../profile/ProfileCompanyInfo'
    ),
  {
    fallback: () => (
      <div className="text-center">
        <CircularProgress />
      </div>
    ),
  }
);
const SignUp = loadable(
  () =>
    import(
      /* webpackChunkName: "js/chunks/signup-SignUp" */ '../signupV2/SignUp'
    ),
  {
    loading: () => (
      <div className="text-center">
        <CircularProgress />
      </div>
    ),
  }
);
// const LanguageSelect = loadable(() => import(/* webpackChunkName: "js/chunks/partials-LanguageSelect" */ './LanguageSelect'));
const HelpCenter = loadable(() =>
  import(
    /* webpackChunkName: "js/chunks/profile-HelpCenter" */ '../profile/HelpCenter'
  )
);

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  navRoot: {
    backgroundColor: theme.palette.primary.main,
    position:"fixed",
   
    width:"100%"
  },
  appBarColor: {
    '&.MuiPaper-root': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },

  grow: {
    flexGrow: 1,
  },
  navBtn: {
    padding: '1rem',
  },
  navBtn2: {
    padding: '5px 1rem',
  },
  activeItem: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  activeMarkets:{
   color:"#fff",backgroundColor:"#FFC400",borderRadius:0, fontWeight:"bolder"

  },
  btnUI: {
    background: '#ffffff2e',
  },
  languageSelect: {
    marginLeft: '10px',
    position: 'relative',
    top: '-8px',
  },

  menuButton: {
    marginRight: theme.spacing(0),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dialogHeader: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    '& h2': {
      fontSize: 24,
      fontWeight: 'bolder',
    },
  },
  whiteButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  drawerBadgesContainer: {
    backgroundColor: '#0000001f',
    margin: theme.spacing(1, 1.5, 1.5),
    borderRadius: '4px',
    '& img': {
      width: 65,
    },
    '& p': {
      fontSize: '13px',
    },
    '& br': {
      display: 'none',
    },
  },
  NavbarBadgesContainer: {
    backgroundColor: '#0000001f',
    margin: theme.spacing(1, 1.5, 1.5),
    borderRadius: '4px',
    padding: theme.spacing(1),
    '& br': {
      display: 'none',
    },
  },
  drawerCompanyInfo: {
    display: 'grid',
    gridTemplateColumns: '20px auto',
    gridGap: theme.spacing(1.25),
    backgroundColor: '#cdd4fd8f',
    padding: theme.spacing(1.5, 2),
  },
  profile: {
    position: 'absolute',
    top: '55px',
    left: '66px',
    background: '#fff',
    boxShadow: '0px 1px 2px #0003',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px 4px 4px',
    border: '1px solid #CCCCCC',
    minWidth: '80px',
  },
  linkColor: {
    color: '#0645AD',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    // '& .MuiListItemIcon-root': {
    //     minWidth: 45
    // },
  },
  _2col: {
    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  dataCount: {
    position: 'absolute',
    background: 'red',
    borderRadius: '8px',
    top: '8px',
    right: 0,
    padding: '2px 4px',
    color: '#fff',
    fontSize: 11,
  },
}));

function NavbarV2(props) {
  const classes = useStyles();
  let location = useLocation();
  const [state, setState] = React.useState({ left: false });
  const [anchorElNotifications, setAnchorElNotifications] =
    React.useState(null);
  const matchesViewport = useMediaQuery('(max-width:580px)');
  const [openOtherDropdown, openOtherDropdownSet] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helpCenterEl,setHelpCenterEl]=React.useState(null)
  const user = useSelector((state) => state.userReducer.user);
  const noCompany = useSelector((state) => state.userReducer.noCompany);
  const user_mode = useSelector((state) => state.userReducer.user_mode);
  const [adCreditData, setAdCreditData] = React.useState('');
  const [reportIssueDialogOpen, reportIssueDialogOpenSet] =
    React.useState(false);
  const [editProfileDialogOpen, editProfileDialogOpenSet] =
    React.useState(false);
  const [issueReportedSuccess, issueReportedSuccessSet] = React.useState(false);
  const [loadingMsgCount, loadingMsgCountSet] = React.useState(false);
  const [apiSuccessResponse, apiSuccessResponseSet] = React.useState(null);
  const [counts, countsSet] = React.useState();
  const [countShow, countShowSet] = React.useState(false);
  const [badgesData, setBadgesData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [unreadNotificationCount, unreadNotificationCountSet] = React.useState(0);
  // const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [isTourOpen, isTourOpenSet] = React.useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const [openSupportListMenu,setOpenSupportListMenu]=React.useState(false);
  const [searchParams] = useSearchParams();
  const isBuyAction = searchParams.get('action') === 'buy';
  const isSellAction = searchParams.get('action') === 'sell';
  const steps = [
    {
      selector: '#myListingButton',
      content: 'This is my first Step',
    },
    {
      selector: '#marketsButton',
      content: 'This is my second Step',
    },
    {
      selector: '#addListingButton',
      content: 'This is my third Step',
    },
    {
      selector: '#openMarketButton',
      content: 'This is my fourth Step',
    },
    {
      selector: '#myWalletIcon',
      content: 'This is my fifth Step',
    },
    {
      selector: '#notificationsIcon',
      content: 'This is my six Step',
    },
    {
      selector: '#profileCompanyInfoIcon',
      content: 'This is my seven Step',
    },
    {
      selector: '#userInfoIcon',
      content: 'This is my eight Step',
    },
  ];

  // useEffect(() => {
  //     if (props.isLoggedIn && location.pathname == "/") {
  //         isTourOpenSet(true);
  //     } else {
  //         isTourOpenSet(false);
  //     }
  // }, [location.pathname, props.isLoggedIn]);

  const supportDrawer=()=>{
    // toggleDrawer("left", false)
    setOpenSupportListMenu(!openSupportListMenu)
  }

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const handleDrawerClose = () => {
    setState(false);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClickNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };




  useEffect(() => {
    if (props.isLoggedIn) {
      props.setLoginModal(false);
      
      props.setSignupModal(false);
    }
  }, [props.isLoggedIn]);

  const BadgeCardLoader = () => (
    <ContentLoader
      speed={2}
      // style={{ width: '100%' }}
      width={300}
      viewBox="0 0 300 120"
      style={{ width: '100%' }}
    >
      <rect x="5" y="29" rx="3" ry="3" width="88" height="6" />
      <rect x="6" y="6" rx="3" ry="3" width="52" height="6" />
      <rect x="198" y="31" rx="3" ry="3" width="88" height="6" />
      <rect x="7" y="111" rx="3" ry="3" width="88" height="6" />
      <circle cx="239" cy="15" r="13" />
      <circle cx="52" cy="79" r="24" />
      <rect x="107" y="110" rx="3" ry="3" width="88" height="6" />
      <circle cx="151" cy="81" r="24" />
      <rect x="202" y="110" rx="3" ry="3" width="88" height="6" />
      <circle cx="246" cy="81" r="24" />
    </ContentLoader>
  );

  const getRatingBadges = async (params) => {
    setLoading(true);
    var params = Object.assign(
      { company_id: user?.active_company?.id },
      params
    );

    try {
      const res = await User.profileBadges(params);
      if (res.data.status == 'success') {
        setBadgesData(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const getUnreadMsgCountNav = async (params) => {
    loadingMsgCountSet(true);
    try {
      const res = await User.getUnreadMsgCount(params);
      if (res.data.status == 'success') {
        countShowSet(true);
        countsSet(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
    loadingMsgCountSet(false);
  };

  React.useEffect(() => {
    if (props.isLoggedIn) {
      if (user) {
        // getRatingBadges();
        // getUnreadMsgCountNav();
      }
      if (user?.latest_credit_consumption_summary) {
        setAdCreditData(
          user?.latest_credit_consumption_summary?.current_balance
        );
      } else {
        setAdCreditData('00');
      }
    }
    // console.log(user)
  }, [user, props.isLoggedIn]);

  const EkycPending = () => (
    <Box
      sx={{
        background: '#EEF2F5',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Box>
        <Box sx={{ fontWeight: 'bold', fontSize: '17px', mb: 0.75 }}>
          eKYC Verification{' '}
          <Box
            component="span"
            sx={{
              background: '#FF0000',
              fontSize: 11,
              color: '#fff',
              px: 0.75,
              py: 0.5,
              borderRadius: 12,
              fontWeight: 'normal',
            }}
          >
            PENDING
          </Box>
        </Box>
        <Box>
          You are 1-step away from profitable trade, <br /> just add your GST to
          begin.
        </Box>
      </Box>
      <IconButton
        size="medium"
        onClick={(e) => {
          navigate('/add-company-ekyc');
          handleMenuClose(e);
          handleDrawerClose(e);
        }}
        color="primary"
        sx={{ minWidth: 35, ml: 1.2 }}
      >
        <ChevronRightIcon fontSize="medium" className="vAlignBottom" />
      </IconButton>
    </Box>
  );

  const LoggedInUserInfo = () => (
    <Box sx={{ textAlign: 'center', mb: 1.5 }}>
      <Box sx={{ position: 'relative', py: 1.5, maxWidth: 100, mx: 'auto' }}>
        <img src="/images/user-avatar.png" width="80" />
  
      </Box>
      <Box
        component="p"
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          m: 0,
          textTransform: 'capitalize !important',
        }}

      >
        {user && user.firstName}{' '} {user && user.lastName}
        <IconButton
          aria-label="Edit"
          size="small"
          color="primary"
          onClick={(e) => {
          
            editProfileDialogOpenSet(e);
            handleDrawerClose(e);
            handleMenuClose(e);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        {/* <ProfileForm user={user} onClickEvent={handleClickOpen} open={open} /> */}
      </Box>
      <Box sx={{ mb: 1 }}>
        {user && user.email}{' '}
        <VerifiedIcon
          fontSize="small"
          sx={{ verticalAlign: 'middle', color: '#85CE1F' }}
        />
      </Box>
      <Box>
        {user && user.contact_number}{' '}
        <VerifiedIcon
          fontSize="small"
          sx={{ verticalAlign: 'middle', color: '#85CE1F' }}
        />
      </Box>
    </Box>
  );

  const BadgesProfileCompanySwitch = (props) => (
    <>
      <LoggedInUserInfo />
      {user && user.active_company !== null && (
        <>
          {/* {loading && (
            <Box p={2} style={{ width: '100%', textAlign: 'center' }}>
              <BadgeCardLoader />
            </Box>
          )} */}
          {!loading && badgesData && (
            <Box
              className={
                props.isNavigation
                  ? classes.NavbarBadgesContainer
                  : classes.drawerBadgesContainer
              }
            >
              {/* <Badges
                isChatModal={false}
                quantity_transacted={badgesData.quantity_transacted}
                transactions_done={badgesData.transactions_done}
                age_of_account={badgesData.age_of_account}
              /> */}
            </Box>
          )}
        </>
      )}
      {/* Switch company with company details dialog and add company */}

      {((user && user.active_company !== 0) || !noCompany) &&
        !loading &&
        badgesData && (
          <Box
            className={classes.drawerCompanyInfo}
            sx={{ margin: '8px 12px 12px' }} 
          >
            <img
              src="/images/icon-company.svg"
              alt="company Name"
              className="vAlignMiddle"
            />
            <Box>
              <Box sx={{ mb: 0.75 }} >
                <MuiLink
                  color="primary"
                  underline="none"
                  //onClick={console.log('')}
                  size="small"
                  sx={{ fontWeight: 'bold', fontSize: '15px' }}
                >
                  {user?.company?.company_name}
                </MuiLink>
                <IconButton size="small" color="primary">
                  <ChevronRightIcon fontSize="small" className="vAlignBottom" />
                </IconButton>
              </Box>

              <Box sx={{ display: 'flex' }} >
                <Rating
                  value={
                    user &&
                    user.rating &&
                    user?.rating !== null &&
                    user?.rating?.avg_round
                  }
                  precision={0.5}
                  readOnly
                  sx={{ fontSize: '1.25rem' }}
                />
                <Box sx={{ ml: 1, px: 0.5 }}>
                  {user?.rating?.avg_round}
                </Box>
                <Box pl={0.5} style={{ borderLeft: '1px solid #ccc' }}>
                  {`${badgesData?.rating_data?.rating_comments.length > 1
                    ? badgesData?.rating_data?.rating_comments.length +
                    ' Reviews'
                    : badgesData?.rating_data?.rating_comments.length +
                    ' Review'
                    }`}
                </Box>
              </Box>

              <Box   sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                {user && user.active_company !== null && (
                  <ProfileCompanyInfo isDrawer={true} />
                )}

                <MuiLink
                  color="inherit"
                  underline="none"
                  onClick={(e) => {
                    navigate('/add-company-eKyc');
                    handleDrawerClose(e);
                    handleMenuClose(e);
                  }}
                  target="_blank"
                  size="small"
                  className={`${classes.linkColor} pointer`}
                  sx={{ marginLeft: 2.5, fontSize: { xs: '13px' } }}
                >
                  <AddIcon
                    fontSize="small"
                    className="vAlign-text-top"
                    sx={{ fontSize: { xs: '13px' } }}
                  />{' '}
                  Add Company
                </MuiLink>
              </Box>
            </Box>
          </Box>
        )}
      {/* user.ekyc_status == 2 no company */}
      {user &&
        (user.active_company === null ||
          noCompany ||
          user.ekyc_status == 2) && <EkycPending />}
    </>
  );

  // Drawer menu lists for small screens
  const sideList = (side) => (
    <div
      className={`w-100 isRelative`}
      role="presentation"
    // onClick={toggleDrawer(side, false)}
    // onKeyDown={toggleDrawer(side, false)}

    >
      <List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space=between',
            alignItems: 'center',
          }}


        >
          <ListItem button to="/" component={Link} >
            <img src={footerLogo}style={{ maxWidth: 180 }} />
          </ListItem>
          <IconButton onClick={toggleDrawer('left', false)}>
            <CancelIcon />
          </IconButton>
        </Box>
        {/* <Divider /> */}

        {props.isLoggedIn && props.user && (
          <>
            <BadgesProfileCompanySwitch isDrawer={true}  />
            <ListItem onClick={toggleDrawer("left", false)}  to="/" component={Link} >
            <ListItemIcon >
                                        <AnalyticsIcon  fontSize="medium" />
                                    </ListItemIcon >
              <ListItemText >
                <LanguageTranslate s="Dashboard" />
              </ListItemText>
            </ListItem>
            <ListItem button onClick={toggleDrawer("left", false)} to="/profile" component={Link} >
              <ListItemIcon>
                <img src='/images/icon-My-Profile.svg' alt='My profile' />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="My Profile" />
              </ListItemText>
            </ListItem>
            {/* <ListItem 
              button
              onClick={toggleDrawer('left', false)}
              to={{ pathname: '/my-safety-circle', search: '?ref=drawer_nav' }}
              component={Link}
            >
              <ListItemIcon>
                <img
                  src="/images/icon-My-Trusted-Companies.svg"
                  alt="My References"
                />
              </ListItemIcon>
              <ListItemText className={classes._2col}>
                <LanguageTranslate s="My References" />
                <ChevronRightIcon color="primary" />
              </ListItemText>
            </ListItem> */}
            {/* <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to={{ pathname: '/my-wallet', search: '?ref=drawer_nav' }}
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/icon-My-Wallet.svg" alt="My wallet" />
              </ListItemIcon>
              <ListItemText className={classes._2col}>
                <LanguageTranslate s="My Wallet" />
                <span style={{ fontSize: '13px' }}>
                  <strong>Balance: {adCreditData}</strong>{' '}
                  <ChevronRightIcon color="primary" />
                </span>
              </ListItemText>
            </ListItem> */}
            {/* <Divider /> */}

            {/* {user?.brand_store_enabled == true &&
              <ListItem
                button
                onClick={toggleDrawer('left', false)}
                to={{ pathname: `/brand-stores/${user?.brand_store_slug}` }}
                component={Link}
                style={{border:"2px red solid"}}
              >
                <ListItemIcon>
                  <img src="/images/brandstore.png" alt="BrandStore" style={{ width: 18 }} />
                </ListItemIcon>
                <ListItemText>
                  <LanguageTranslate s="My Brand Store" />
                </ListItemText>
              </ListItem>
            } */}
            {/* <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to={{ pathname: `/brand-stores` }}
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/brandstore.png" alt="BrandStore" style={{ width: 18 }} />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Brand Stores" />
              </ListItemText>
            </ListItem> */}
            {/* <Divider /> */}
            {/* <div id="google_translate_element" style={{border:"2px red solid"}}></div> */}
            <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to="/dashboard/inbox"
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/icon-My-Inbox.svg" alt="My inbox" />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Inbox" />
              </ListItemText>
            </ListItem>
            {/* <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to={{ pathname: '/deals-and-trades', search: '?ref=drawer_nav' }}
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/icon-My-Sales.svg" alt="My Sales" />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s={'My Deals'} />
              </ListItemText>
            </ListItem> */}
            {/* <Divider /> */}
            {/* <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to="/dashboard/my-ratings"
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/icon-My-Ratings.svg" alt="My Ratings" />
              </ListItemIcon>
              <ListItemText className={classes._2col}>
                <LanguageTranslate s="My Ratings" />
                <ChevronRightIcon color="primary" />
              </ListItemText>
            </ListItem> */}

            {/* <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to={{ pathname: '/blocked-companies', search: '?ref=drawer_nav' }}
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/icon-Blocked-List.svg" alt="Blocked List" />
              </ListItemIcon>
              <ListItemText className={classes._2col}>
                <Box>
                  <LanguageTranslate s="Blocked List" />
                  <Box
                    component="p"
                    sx={{
                      display: 'inline',
                      background: '#85ce1f',
                      color: '#fff',
                      borderRadius: '8px',
                      ml: 0.75,
                      px: 0.75,
                      fontSize: '11px',
                    }}
                  >
                    New
                  </Box>
                </Box>
                <ChevronRightIcon color="primary" />
              </ListItemText>
            </ListItem> */}
            {/* <Divider /> */}
            <ListItem
              button
              onClick={toggleDrawer('left', false)}
              to={{ pathname: '/markets', search: '?ref=drawer_nav' }}
              component={Link}
            >
              <ListItemIcon>
                <img src="/images/icon-Select-Markets.svg" alt="markets" />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Products" />
              </ListItemText>
            </ListItem>
          
<ListItem onClick={(e) => {
                         toggleDrawer('left', false);
                         storeCTAClick(e, 'tickets-raised-via-help-center');
                         // handleMenuClose(e);
                         reportIssueDialogOpenSet(e);
                         return e;
                       }}><ListItemIcon>
                       <HelpOutlineRoundedIcon /> 
                   </ListItemIcon>
                   <ListItemText>
                <LanguageTranslate s="Support" />
              </ListItemText>
                       {/* <LanguageTranslate s="Add Company" /> */}
                     </ListItem>
                     
            <ListItem
              button
              component={Link}
              to={{ pathname: '/logout', search: '?ref=drawer_nav' }}
              onClick={() => {
                toggleDrawer('left', false);
                sessionStorage.removeItem('loginNumber');
                trackingEvents.logoutFromNavbar();
            }}
            >
              <ListItemIcon>
                <img src="/images/icon-Logout.svg" alt="Logout" />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Logout" />
              </ListItemText>
            </ListItem>
          </>
        )}
        {!props.isLoggedIn && (
          <>
            <Box sx={{ p: 2, mb: 1.5 }}>
              <Box
                component="p"
                sx={{
                  fontSize: '19px',
                  mb: 1,
                  mt: 0,
                  textTransform: 'capitalize !important',
                }}
              >
                Join Us
              </Box>
              <Box sx={{ mb: 1.5 }}>
              Join us, to get an unrestricted, personalized experience and alerts of the plant saplings market.
              </Box>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                component={Link}
                to={`/sign-up/${cEncrypt(0)}`}
                onClick={toggleDrawer('left', false)}
              >
                Register for free!
              </Button>
            </Box>
            <ListItem
              button
              to="/"
              component={Link}
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Dashboard" />
              </ListItemText>
            </ListItem>
         
            <ListItem
              button
              to={{ pathname: '/markets', search: '?ref=drawer_nav' }}
              component={Link}
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <img
                  // src="/images/vessel-sIcon.svg"
                  src="/images/markets-default.svg"
                  style={{ width: 18, marginLeft: 3 }}
                  alt="Markets"
                />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Products" />
              </ListItemText>
            </ListItem>

         
{/*     
            <ListItem
              button
              component={Link}
              to={{ pathname: '/news', search: '?ref=drawer_nav' }}
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="News" />
              </ListItemText>
            </ListItem> */}
            <ListItem
              button
              component={Link}
              to={{ pathname: '/contact', search: '?ref=drawer_nav' }}
              onClick={toggleDrawer('left', false)}
            >
              <ListItemIcon>
                <PermContactCalendarIcon />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="Support" />
              </ListItemText>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  // Set mobile, Buyer and Seller menu dropdowns

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleHelpCenterMenuOpen = (event) => {
  //   setHelpCenterEl
  // }

  // Close menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Renders user's menu when logged in
  const RenderProfileMenu = (props) => (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="user-menu"
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}

    >
      <Box sx={{ display: 'flex' }} component="li">
        {/* Profile menu column 1 */}
        <Box sx={{ p: 1, borderRight: '1px solid #ddd' }}>
          <BadgesProfileCompanySwitch isNavigation={true} />
        </Box>
        {/* Profile menu column 2 */}
        <Box style={{ display:"flex",flexDirection:"column"}} >
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/profile', search: '?ref=nav_profile' }}
          >
            <ListItemIcon>
              <img src="/images/icon-My-Profile.svg" alt="My profile" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="My Profile" />
            </ListItemText>
          </MenuItem>

          {/* <MenuItem
            onClick={handleMenuClose}
            to={{ pathname: '/my-wallet', search: '?ref=nav_profile' }}
            component={Link}
          >
            <ListItemIcon>
              <img src="/images/icon-My-Wallet.svg" alt="My wallet" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="My Wallet" />
              &nbsp;{' '}
              <strong style={{ fontSize: '13px' }}>
                Balance: {adCreditData}
              </strong>
            </ListItemText>
          </MenuItem> */}
          {user?.brand_store_enabled == true &&
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to={{ pathname: `/brand-stores/${user?.brand_store_slug}` }}
              target="_blank"

            >
              <ListItemIcon>
                <img src="/images/brandstore.png" alt="BrandStore" style={{ width: 16 }} />
              </ListItemIcon>
              <ListItemText>
                <LanguageTranslate s="My Brand Store" />
              </ListItemText>
            </MenuItem>
          }
          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/dashboard/my-listings' }}
          >
            <ListItemIcon>
              <img src="/images/icon-my-listings.svg" alt="My Sales" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="My Listings" />
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/deals-and-trades', search: '?ref=nav_profile' }}
          >
            <ListItemIcon>
              <img src="/images/icon-My-Sales.svg" alt="My Sales" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s={'Deals'} />
            </ListItemText>
          </MenuItem>

          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/re-post' }}
          >
            <ListItemIcon>
              <img src="/images/icon-my-listings.svg" alt="RePost" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="RePost" />
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/dashboard/inbox' }}
          >
            <ListItemIcon>
              <img src="/images/icon-My-Inbox.svg" alt="My inbox" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="Inbox" />
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/dashboard/my-ratings' }}
          >
            <ListItemIcon>
              <img src="/images/icon-My-Ratings.svg" alt="My Ratings" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="My Ratings" />
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/my-safety-circle', search: '?ref=nav_profile' }}
          >
            <ListItemIcon>
              <img
                src="/images/icon-My-Trusted-Companies.svg"
                alt="My References"
              />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="My References" />
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/blocked-companies', search: '?ref=nav_profile' }}
          >
            <ListItemIcon>
              <img src="/images/icon-Blocked-List.svg" alt="Blocked List" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="Blocked List" />
              <Box
                component="span"
                sx={{
                  background: '#85ce1f',
                  color: '#fff',
                  borderRadius: '8px',
                  ml: 0.75,
                  px: 0.75,
                  fontSize: '11px',
                }}
              >
                New
              </Box>
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{
              pathname: '/markets/select-markets',
              search: '?ref=nav_profile',
            }}
          >
            <ListItemIcon>
              <img src="/images/icon-Select-Markets.svg" alt="Select markets" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="Select Markets" />
            </ListItemText>
          </MenuItem> */}


          {/* <List className={classes.list}>
            <ListItem
              button
              onClick={(e) => openOtherDropdownSet(!openOtherDropdown)}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <img src="/images/icon-Others.svg" alt="information pages" />
              </ListItemIcon>
              <ListItemText primary="Others" />
              {openOtherDropdown ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openOtherDropdown} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem
                  button
                  to={{ pathname: '/about-us', search: '?ref=nav_profile' }}
                  component={Link}
                  onClick={handleMenuClose}
                >
                  <ListItemText sx={{ marginLeft: '36px' }}>
                    <LanguageTranslate s="About" />
                  </ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to={{ pathname: '/events', search: '?ref=nav_profile' }}
                  onClick={handleMenuClose}
                >
                  <ListItemText sx={{ marginLeft: '36px' }}>
                    <LanguageTranslate s="Events" />
                  </ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to={{ pathname: '/blog', search: '?ref=nav_profile' }}
                  onClick={handleMenuClose}
                >
                  <ListItemText sx={{ marginLeft: '36px' }}>
                    <LanguageTranslate s="Blog" />
                  </ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to={{ pathname: '/news', search: '?ref=nav_profile' }}
                  onClick={handleMenuClose}
                >
                  <ListItemText sx={{ marginLeft: '36px' }}>
                    <LanguageTranslate s="News" />
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider /> */}
{/*
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/pricing-plans', search: '?ref=nav_profile' }}
          >
            <ListItemIcon>
              <img src="/images/icon-Pricing.svg" alt="Pricing" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="Pricing" />
            </ListItemText>
          </MenuItem> */}

          {/* <MenuItem
            onClick={(e) => {
              storeCTAClick(e, 'tickets-raised-via-help-center');
              handleMenuClose(e);
              reportIssueDialogOpenSet(e);
              return e;
            }}
            component={Link}
            to="#!"
          >
            <ListItemIcon>
              <img src="/images/icon-Help-Center.svg" alt="Help center" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="Help Center" />
            </ListItemText>
          </MenuItem> */}
          {/* <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to="/profile/support-assigned-dealer"
      >
        <ListItemIcon>
          <img src="/images/icon-Support.svg" alt="Support" />
        </ListItemIcon>
        <ListItemText>
          <LanguageTranslate s="Support" />
        </ListItemText>
      </MenuItem> */}



          {/* <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={{ pathname: '/contact', search: '?ref=nav_profile' }}
          >
            <ListItemIcon>
              <img src="/images/icon-Contact-Us.svg" alt="Contact us" />
            </ListItemIcon>
            <ListItemText>
              <LanguageTranslate s="Contact Us" />
            </ListItemText>
          </MenuItem> */}


        </Box>
      </Box>
    </Menu>
  );

  // Open login dialog
  const loginDialog = () => {
    if (!props.loginModal) return <></>;

    return (
      <Dialog
        open={props.loginModal}
        onClose={(e) => {
          props.setLoginModal(false);
          // ReactGA.event({ category: 'User', action: 'Closed Login Dialog' });
        }}
        aria-labelledby="form-dialog-title"
      >
        <LoginForm
        // onRedirect={path => { console.log(path) }}
        />
      </Dialog>
    );
  };

const handleNotificationEvent = (e) => unreadNotificationCountSet(e.unreadCount);

    React.useEffect(() => {
        if(props.isLoggedIn)
        {
            socket.on("notification", handleNotificationEvent);
        }

        return () => {
            socket.off("notification", handleNotificationEvent);
        };
    }, [props.isLoggedIn]);

  // Open signup dialog
  const signUpDialog = () => {
    if (!props.signupModal) return <></>;

    return (
      <Dialog
        open={props.signupModal}
        onClose={(e) => {
          props.setSignupModal(false);
          // ReactGA.event({ category: 'User', action: 'Closed Signup Dialog' });
        }}
        aria-labelledby="form-dialog-title"
      >
        <SignUp navbar={true} />
      </Dialog>
    );
  };

  return (
    <>
      {/* {matchesViewport ? <AppDownloadMobile /> : <AppDownloadWeb />} */}
      <div className={`${classes.grow} ${classes.navRoot}`} style={{backgroundColor:"#19243B", top:0,position:"sticky",zIndex:10}}> 
        <Container maxWidth="lg" style={{ padding: 0 ,backgroundColor:"#19243B"}} >
          <AppBar
            position="static"
            elevation={0}
            style={{backgroundColor:"#19243B"}}
            className={`${classes.appBarColor}`}
            
          >
            <Toolbar sx={{ minHeight: 'auto !important' }}>
              {/* Menu button for drawer navigation for small and mobile screens */}
              <Hidden only={['md', 'lg', 'xl']}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer('left', true)}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>

              {/* Left column */}
              {/* Application Logo */}
              {/* <Hidden xsDown> */}
              <Box className={classes.grow}>
                <Link
                onClick={()=>trackingEvents.navbarHomeButton()}
                  to="/"
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}
                >
                  <img
                    src={companyLogo}
                    alt="Mandi.trade logo"
                    style={{ width: 100,marginRight:2, verticalAlign: 'top', }}

                  />
                </Link>
                {/* Desktop nav items for unauthenticated users */}

                {/* Hide for small screens */}
                {props.isLoggedIn && (
                  <Hidden mdDown>
                    {/* <Button
                      aria-label="Dashboard"
                      aria-haspopup="true"
                      color="inherit"
                      className={`${location.pathname == '/' ? classes.activeItem : ''
                        } ${classes.navBtn}`}
                      variant="text"
                      size="small"
                      margin="normal"
                      component={Link}
                      to="/"
                      onClick={()=>trackingEvents.homeButton()}
                    >
                      {
                        location.pathname == '/' ?
                        <img src="/images/home-active.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} /> :
                        <img src="/images/home-default.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} />
                      }

                      <LanguageTranslate s="Home" />
                    </Button> */}
                    <Button
                      aria-label="Dashboard"
                      aria-haspopup="true"
                      id="myListingButton"
                      color="inherit"
                      className={`${location.pathname == '/dashboard/my-listings'
                        ? classes.activeItem
                        : ''
                        } ${classes.navBtn}`}
                      variant="text"
                      size="small"
                      margin="normal"
                      component={Link}
                      to="/dashboard/my-listings"
                      onClick={()=>trackingEvents.myListings()}
                    >
                      {
                        location.pathname == '/dashboard/my-listings' ?
                        <img src="/images/listings-active.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} /> :
                        <img src="/images/listings-default.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} />
                      }
                      <LanguageTranslate s="My Listings" />{' '}
                      {countShow == true &&
                        counts?.my_listing_unread_chat_count_data
                          ?.nav_my_listing_unread_chat_count != 0 && (
                          <Box
                            className={
                              countShow == true ? classes.dataCount : ''
                            }
                          >
                            {
                              counts?.my_listing_unread_chat_count_data
                                ?.nav_my_listing_unread_chat_count
                            }
                          </Box>
                        )}
                    </Button>

                    <Button
                      aria-label="Markets"
                      aria-haspopup="true"
                      id="marketsButton"
                      color="inherit"
                      className={`${location.pathname == '/markets'
                        ? classes.activeMarkets
                        : classes.activeMarkets
                        } ${classes.navBtn}`}

                      variant="text"
                      size="small"
                      margin="normal"
                      onClick={(e) => {
                        // storeCTAClick(e, 'all-vessel-list-top-navigation');
                        // return e;
                        trackingEvents.productsPage()
                      }}
                      component={Link}
                      to="/markets"
                    >
                      {
                        window.location.pathname.includes('/markets') ?
                        <img src="/images/markets-active.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} /> :
                        <img src="/images/markets-default.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} />
                      }
                      <LanguageTranslate s="Products" />
                    </Button>

                    <Button
                      aria-label="Add Listing"
                      aria-haspopup="true"
                      id="addListingButton"
                      color="inherit"
                      className={`${location.pathname == '/dashboard/inbox'
                        ? classes.activeItem
                        : ''
                        } ${classes.navBtn}`}
                      variant="text"
                      size="small"
                      margin="normal"
                      component={Link}
                      to={'/dashboard/inbox'}
                      onClick={()=>trackingEvents.myInbox()}
                    >
                      {
                        location.pathname == '/dashboard/inbox' ?
                        <img src="/images/inbox-active.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} /> :
                        <img src="/images/inbox-default.svg" alt="home" width={18} height={16} style={{ width: 18, marginRight: 4}} />
                      }
                      Inbox{' '}
                      {countShow == true &&
                        counts?.inbox_unread_chat_count_data
                          ?.nav_inbox_unread_chat_count != 0 && (
                          <Box
                            className={
                              countShow == true ? classes.dataCount : ''
                            }
                          >
                            {
                              counts?.inbox_unread_chat_count_data
                                ?.nav_inbox_unread_chat_count
                            }
                          </Box>
                        )}
                    </Button>
                    <Button
                                        aria-label="My deals"
                                        aria-haspopup="true"
                                        color="inherit"
                                        className={`${location.pathname == '/deals-and-trades' ? classes.activeItem : ''} ${classes.navBtn}`}
                                        variant="text"
                                        size="small"
                                        margin="normal"
                                        component={Link}
                                        to={'/deals-and-trades'}
                                        onClick={()=>trackingEvents.myDeals()}
                                    >
                                      {
                                        location.pathname == '/deals-and-trades' ?
                                        <img src="/images/deals-active.svg" alt="deals" width={18} height={16} style={{ width: 18, marginRight: 4}} /> :
                                        <img src="/images/deals-default.svg" alt="deals" width={18} height={16} style={{ width: 18, marginRight: 4}} />
                                      }
                                        My Deals
                                    </Button>
                                    {/* repost hidden untill not working on user end */}

                                    {/* till repost is not solved, navigating it to products */}
                                    <Button
                                     aria-label="Markets"
                                     aria-haspopup="true"
                                     id="marketsButton"
                                     color="inherit"
                                     className={
                                      `${isBuyAction ? classes.activeItem : ''} ${classes.navBtn}`
                                      // location.pathname == '/add-your-products' 
                                      // ?
                                      // classes.activeNavItem 
                                      // : classes.navItem
                                      // `${classes.activeItem} : '' ${classes.navBtn}`
                                    }
                                     variant="text"
                                     size="small"
                                     margin="normal"
                                     onClick={(e) => { console.log({path:location.pathname}); trackingEvents.buyButton()
                                     console.log({isBuyAction})
                                     }}
                                     component={Link}
                                     to="/add-your-products?action=buy"
                                    >
                                      {
                                        location.pathname === '/add-your-products' && isBuyAction ? (
                                        <img src="/images/buy-active.png" alt="deals" width={18} height={16} style={{ width: 18, marginRight: 4}} /> 
                                          ) : (
                                        <img src="/images/buy-default.png" alt="deals" width={18} height={16} style={{ width: 18, marginRight: 4}} />
                                        ) 
                                      }
                                        Buy
                                    </Button> 
                                     <Button
                                     aria-label="Markets"
                                     aria-haspopup="true"
                                     id="marketsButton"
                                     color="inherit"
                                     className={
                                      `${isSellAction ? classes.activeItem : ''} ${classes.navBtn}`
                                      // location.pathname == '/add-your-products'
                                      // isBuyAction ? classes.activeNavItem : classes.navItem
                                    }
                                     variant="text"
                                     size="small"
                                     margin="normal"
                                     onClick={(e) => {
                                      //  storeCTAClick(e, 'all-vessel-list-top-navigation');
                                      //  return e;
                                       trackingEvents.sellButton();
                                       console.log({isBuyAction})
                                     }}
                                     component={Link}
                                    //  to="/markets"
                                     to="/add-your-products?action=sell"
                                        // aria-label="Sell"
                                        // aria-haspopup="true"
                                        // color="inherit"
                                    
                                        // variant="text"
                                        // size="small"
                                        // margin="normal"
                                        // to="/re-post"
                                        // component={Link}
                                        // className={location.pathname == '/re-post' ? classes.activeNavItem : classes.navItem}
                                    >
                                      {
                                        location.pathname == '/add-your-products' && 
                                        // searchParams.get('action') == 'buy'
                                        isSellAction ?
                                        <img src="/images/sell-active.png" alt="deals" width={18} height={16} style={{ width: 18, marginRight: 4}} /> :
                                        <img src="/images/sell-default.png" alt="deals" width={18} height={16} style={{ width: 18, marginRight: 4}} />

                                      }
                                        Sell
                                    </Button> 
                    {/* <Button
                      aria-label="BrandStore"
                      aria-haspopup="true"
                      color="inherit"
                      className={`${location.pathname == '/brand-stores'
                        ? classes.activeItem
                        : ''
                        } ${classes.navBtn}`}
                      variant="text"
                      size="sup"
                      margin="normal"
                      component={Link}
                      to={'/brand-stores'}
                    >
                      Brand Stores{' '}
                      <Box
                        component="span"
                        sx={{
                          position: 'absolute',
                          right: '-16px',
                          top: '10px',
                          background: '#f44336',
                          color: '#fff',
                          borderRadius: '8px',
                          ml: 0.75,
                          px: 0.75,
                          fontSize: '10px',
                        }}
                      >
                        New
                      </Box>
                    </Button> */}
                  </Hidden>
                )}
              </Box>

              {/* Right column */}
              {/* Desktop nav items for..  */}
              <div className={classes.sectionDesktop} >
                <div className={`${classes.sectionDesktop} ${classes.root}`} >
                  {/* ..authenticated users */}
                  {props.isLoggedIn && (
                    <>
                      <Box
                        sx={{
                          margin: '0 !important',
                          marginRight: '8px !important',
                        }}
                        id="notificationsIcon"
                      >
                        <IconButton
                          aria-label={`Show ${unreadNotificationCount} new notifications`}
                          sx={{ borderRadius: '4px' }}
                          className={`${classes.btnUI} text-white`}
                          size="small"
                          component={Link}
                          to="/notifications"
                          onClick={(e) => {
                            handleClickNotifications(e);
                            // storeCTAClick(e, 'notification-bell-icon');
                            // return e;
                            trackingEvents.notificationsIcon();
                          }}
                        >
                          <Badge
                            badgeContent={
                              unreadNotificationCount > 9
                                ? '9+'
                                : unreadNotificationCount
                            }
                            color="secondary"
                            size="small"
                          >
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>
                      </Box>

                      <Menu
                        id="fade-menu"
                        anchorEl={anchorElNotifications}
                        keepMounted
                        open={false}
                        onClose={handleCloseNotifications}
                        TransitionComponent={Fade}
                      >
                        {props.unreadNotifications &&
                          props.unreadNotifications.length > 0 &&
                          props.unreadNotifications
                            .slice(0, 9)
                            .map((notification, index) => (
                              <MenuItem key={index}>
                                {notification.title}
                              </MenuItem>
                            ))}
                      </Menu>

                      {/* Switch company with company details dialog and add company */}
                    
                      {user && user.active_company !== null && (
                        <ProfileCompanyInfo
                          isNavigation={true}
                          isNavbarDirectChild={true}
                        />
                      )}

                      <Box
                        sx={{
                          margin: '0 !important',
                          marginLeft: '8px !important',
                        }}
                        id="userInfoIcon"
                       
                      >
                        <Button
                          edge="end"
                          size="small"
                          aria-label="User"
                          color="inherit"
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleProfileMenuOpen(e);
                            trackingEvents.userProfileIcon();
                          }}
                          variant="text"
                          className={`${classes.btnUI} text-white`}
                        >
                          <AccountCircle /> &nbsp; {props?.user?.first_name}
                          <KeyboardArrowDownIcon />
                        </Button>
                      </Box>
                      {/* support and logout section */}
                      <Box
                        sx={{
                          margin: '0 !important',
                          marginLeft: '8px !important',
                        }}
                        id="logoutIcon"
                      >
                        <Button
                          edge="end"
                          size="small"
                          aria-label="logout"
                          color="inherit"
                          aria-haspopup="true"
                          component={Link}
                          to="/logout"
                          onClick={() => {
                            toggleDrawer('left', false);
                            sessionStorage.removeItem('loginNumber');
                            trackingEvents.logoutFromNavbar()
                          }}                          
                          variant="text"
                          className={`${classes.btnUI} text-white`}
                        >
                          <LogoutIcon />

                        </Button>
                      </Box>



                    </>
                  )}
                </div>

                {/* ..unauthenticated users */}
                <>
                  {loginDialog()}
                  {signUpDialog()}

                  {!props.isLoggedIn && (
                    <div
                      className={`${classes.sectionDesktop} ${classes.root}`}
                    >
                      {/* disabling product nav tab when used is not logged in */}
                      {/* <Button
                        aria-label="Markets"
                        aria-haspopup="true"
                        color="inherit"

                        variant="text"
                        size="small"
                        margin="normal"
                        component={Link}
                        to="/markets"
                       

                      >
                        <LanguageTranslate s="Products" />
                      </Button> */}
                     
                      {/* <Button
                        aria-label="Benchmarks"
                        aria-haspopup="true"
                        color="inherit"
                        className={`${location.pathname == '/guest/benchmark'
                          ? classes.activeItem
                          : ''
                          } ${classes.navBtn2}`}
                        variant="text"
                        size="small"
                        margin="normal"
                        component={Link}
                        to="/guest/benchmark"
                      >
                        <LanguageTranslate s="Benchmarks" />
                      </Button> */}

                       {/* disabling pricing  nav tab when used is not logged in */}
                      {/* <Button
                        aria-label="plan and pricing menu"
                        aria-haspopup="true"
                        color="inherit"
                        className={`${location.pathname == '/pricing-plans'
                          ? classes.activeItem
                          : ''
                          } ${classes.navBtn2}`}
                        variant="text"
                        size="small"
                        margin="normal"
                        component={Link}
                        to="/pricing-plans"
                      >
                        <LanguageTranslate s="Pricing" />
                      </Button> */}
                      {/* <Button
                        aria-label="Events"
                        aria-haspopup="true"
                        color="inherit"
                        className={`${location.pathname == '/events'
                          ? classes.activeItem
                          : ''
                          } ${classes.navBtn2}`}
                        variant="text"
                        size="small"
                        margin="normal"
                        component={Link}
                        to="/events"
                      >
                        <LanguageTranslate s="Events" />
                      </Button> */}

                     
                      <Button
                        component={Link}
                        to={`/markets`}
                        style={{
                          backgroundColor: "#F78702",
                          color: "#19243B",
                          transition: "background-color 0.3s, color 0.3s", 
                          borderRadius:"10%",
                          display:'flex',
                          alignItems:"center",
                          justifyContent:"space-between",
                        }}
                        // className={`text-#19243B`}
                        size="small"
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = 'white';
                          e.currentTarget.style.color = '#19243B';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "#F78702";
                          e.currentTarget.style.color = '#19243B';
                          e.currentTarget.style.borderRadius="10%"
                        }}
                        onClick={()=>trackingEvents.navbarProducts()}
                      >
                          <img
                            src="/images/markets-active.svg"
                            alt="products"
                            width={18} height={16} 
                            style={{
                                width: 18, 
                                marginRight: 4
                                // filter: "invert(100%)", 
                            }}
                        />
                        <LanguageTranslate s="Products" />
                      </Button>
                      {/* <Button
                      aria-label="Dashboard"
                      aria-haspopup="true"
                      id="myListingButton"
                      color="inherit"
                      // className={`${location.pathname == '/dashboard/my-listings'
                      //   ? classes.activeItem
                      //   : ''
                      //   } ${classes.navBtn}`}
                      variant="text"
                      size="small"
                      margin="normal"
                      component={Link}
                      to="/dashboard/my-listings"
                    >
                      <LanguageTranslate s="My Listings" />{' '}
                      {countShow == true &&
                        counts?.my_listing_unread_chat_count_data
                          ?.nav_my_listing_unread_chat_count != 0 && (
                          <Box
                            className={
                              countShow == true ? classes.dataCount : ''
                            }
                          >
                            {
                              counts?.my_listing_unread_chat_count_data
                                ?.nav_my_listing_unread_chat_count
                            }
                          </Box>
                        )}
                    </Button> */}
   {/* <Button
                      aria-label="Add Listing"
                      aria-haspopup="true"
                      id="addListingButton"
                      color="inherit"
                      // className={`${location.pathname == '/dashboard/inbox'
                      //   ? classes.activeItem
                      //   : ''
                      //   } ${classes.navBtn}`}
                      variant="text"
                      size="small"
                      margin="normal"
                      component={Link}
                      to={'/dashboard/inbox'}
                    >
                      Inbox{' '}
                      {countShow == true &&
                        counts?.inbox_unread_chat_count_data
                          ?.nav_inbox_unread_chat_count != 0 && (
                          <Box
                            className={
                              countShow == true ? classes.dataCount : ''
                            }
                          >
                            {
                              counts?.inbox_unread_chat_count_data
                                ?.nav_inbox_unread_chat_count
                            }
                          </Box>
                        )}
                    </Button> */}
                    {/* <Button
                                        aria-label="My deals"
                                        aria-haspopup="true"
                                        color="inherit"
                                        // className={`${location.pathname == '/deals-and-trades' ? classes.activeItem : ''} ${classes.navBtn}`}
                                        variant="text"
                                        size="small"
                                        margin="normal"
                                        component={Link}
                                        to={'/deals-and-trades'}
                                    >
                                        My Deals
                                    </Button> */}
                                    {/* <Button
                        component={Link}
                        to={`/markets`}
                        style={{
                  
                          color: "#FFF",
                   
                        }}
                    
                        size="small"
                   
                      >
                        <LanguageTranslate s="Sell" />
                      </Button> */}
                                    <Button
                        component={Link}
                        to={`/login`}
                        onClick={()=>trackingEvents.loginButton()}
                        // }}
                        className={`text-white`}
                        size="small"
                      >
                        <LanguageTranslate s="Login" />
                      </Button>

                      <Button
                        component={Link}
                        to={`/sign-up/${cEncrypt(0)}`}
                        onClick={(e) => {
                          //props.setSignupModal(true);
                          // ReactGA.event({
                          //   category: 'User',
                          //   action: 'Opened Signup Dialog',
                          // });
                          trackingEvents.registerButton()
                        }}
                        //variant="outlined"
                        className={classes.whiteButton}
                        size="small"
                      >
                        <LanguageTranslate s="REGISTER" />
                      </Button>
                    </div>
                  )}
                </>
              </div>

              {/* Mobile nav items for..  */}
              <div className={classes.sectionMobile}  >
                {/* ..authenticated users */}
                {props.isLoggedIn && (
                  <Hidden mdUp>
                    <Grid Container style={{display:"flex"}}>
                    <Grid item sm={11} sx={{ borderRadius: '4px',marginRight:1 }}
                        className={`${classes.btnUI} text-white`}>
                   {user && user.active_company !== null && (
                        <ProfileCompanyInfo
                          isNavigation={true}
                          isNavbarDirectChild={true}
                        />
                      )}
                   </Grid>
                   <Grid item sm={1} style={{marginLeft:3}}><IconButton
                        aria-label={`Show ${unreadNotificationCount} new notifications`}
                        sx={{ borderRadius: '4px' }}
                        className={`${classes.btnUI} text-white`}
                        size="small"
                        component={Link}
                        to="/notifications"
                        onClick={(e) => {
                          handleClickNotifications(e);
                          storeCTAClick(e, 'notification-bell-icon');
                          return e;
                        }}
                      >
                        <Badge
                          badgeContent={
                            unreadNotificationCount > 9
                              ? '9+'
                              : unreadNotificationCount
                          }
                          color="secondary"
                          size="small"
                        >
                          <NotificationsIcon />
                        </Badge>
                      </IconButton></Grid>
               
                   
                    
                    </Grid>
                    
                   
                   
                  </Hidden>
                )}

                {/* ..unauthenticated users */}
                {!props.isLoggedIn && (
                  <>
                    <Hidden mdUp>
                      <Link to="/login">
                        <Button className={`text-white`} size="small">
                          <LanguageTranslate s="Login" />
                        </Button>
                      </Link>

                      <Link to={`/sign-up/${cEncrypt(0)}`}>
                        <Button
                          variant="outlined"
                          className={`${classes.buttonWhiteOutlined} bg-white`}
                          size="small"
                        >
                          <LanguageTranslate s="REGISTER" />
                        </Button>
                      </Link>
                    </Hidden>
                  </>
                )}
              </div>
            </Toolbar>
          </AppBar>
        </Container>

        {/* Keep profile menu hidden for small screens */}
        <Hidden mdDown>
          <RenderProfileMenu />
        </Hidden>

        {/* Closing the drawer if clicked anywhere */}
        <Drawer
          open={state.left}
          onClose={toggleDrawer('left', false)}
          PaperProps={{ sx: { width: ['65%', 351] } }}
        >
          {sideList('left')}
        </Drawer>

        {/* report issue dialog */}
        <Dialog
          open={reportIssueDialogOpen}
          keepMounted
          TransitionComponent={Transition}
          onClose={(e) => {
            reportIssueDialogOpenSet(false);
          }}
        >
          {issueReportedSuccess && (
            <>
              <DialogTitle align="center" className={`${'text-white'}`}>
                <AnimatedSuccess />
              </DialogTitle>
              <DialogContent>
                <Box sx={{ textAlign: 'center' }} mb={1}>
                  <Typography variant="h5">Great!!</Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle1">
                    {apiSuccessResponse}
                  </Typography>
                </Box>
                <Box mb={2} sx={{ textAlign: 'center' }}>
                  <Button
                    style={{
                      background: '#4CAF50',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                    type="button"
                    onClick={(e) => {
                      reportIssueDialogOpenSet(false);
                      issueReportedSuccessSet(false);
                    }}
                    variant="contained"
                    size="medium"
                    disableElevation
                  >
                    Okay
                  </Button>
                </Box>
              </DialogContent>
            </>
          )}
          {!issueReportedSuccess && (
            <>
              <IconButton
                aria-label="close"
                className="closeBtn"
                size="small"
                //color="secondary"
                onClick={(e) => {
                  reportIssueDialogOpenSet(false);
                }}
              >
                <CancelIcon />
              </IconButton>
              <DialogTitle
                id="alert-dialog-title"
                className={`text-center ${classes.dialogHeader}`}
              >
                Report Issue
              </DialogTitle>
              <DialogContent dividers={true}>
                {reportIssueDialogOpen && (
                  <HelpCenter
                    apiSuccessResponseSet={apiSuccessResponseSet}
                    issueReportedSuccessSet={issueReportedSuccessSet}
                  />
                )}
              </DialogContent>
            </>
          )}
        </Dialog>

        {/* edit profile dialog */}
        <Dialog
          open={editProfileDialogOpen}
          keepMounted
          TransitionComponent={Transition}
          onClose={(e) => {
            editProfileDialogOpenSet(false);
          }}
        >
          <IconButton
            aria-label="close"
            className="closeBtn"
            size="small"
            //color="secondary"
            onClick={(e) => {
              editProfileDialogOpenSet(false);
            }}
          >
            <CancelIcon />
          </IconButton>
          <DialogTitle id="alert-dialog-title">Edit Profile</DialogTitle>
          <DialogContent dividers={true}>
            <EditProfileDialog
              user={user}
              cancelButton={true}
              onClose={(e) => {
                editProfileDialogOpenSet(false);
              }}
            />
          </DialogContent>
        </Dialog>
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={(e) => isTourOpenSet(false)}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    isLoggedIn: state.userReducer.isLoggedIn,
    messagesCount: state.userReducer.messagesCount,
    unreadNotificationCount: state.userReducer.unreadNotificationCount,
    unreadNotifications: state.userReducer.unreadNotifications,
    loginModal: state.commonReducer.loginModal,
    signupModal: state.commonReducer.signupModal,
  };
};

export default connect(mapStateToProps, {
  fetchUnreadNotificationCount,
  checkAndLoadUser,
  loginUser,
  logoutUser,
  setLoginModal,
  setSignupModal,
})(NavbarV2);
